import Heading from 'components/heading/heading'
import Panel from 'components/panel/panel'
import Btn from 'components/btn/btn'
import Meta from 'components/meta/meta'

export default function Page() {
    return (
        <Panel desktop>
            <Meta title="Puslapis nerastas" description="Atsiprašome, bet puslapis, kurio ieškote, nerastas." />
            <Heading tag="h1" size="36" className="mb18">
                Puslapis nerastas
            </Heading>
            <div>Atsiprašome, bet puslapis, kurio ieškote, nerastas.</div>
            <br />
            <br />
            <Btn href="/" style="yellow">
                Eiti į titulinį
            </Btn>
        </Panel>
    )
}
